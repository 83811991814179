@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Open Sans", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Open Sans", monospace;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.font-open-sans {
  font-family: "Open Sans", sans-serif;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.steps .step-primary + .step-primary:before,
.steps .step-primary:after,
.steps .step-primary > .step-icon {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(39 196 50 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  --step-bg: #27c432 !important;
  --step-fg: #ffffff !important;
}

.custom-steps.steps .step-primary + .step-primary:before,
.custom-steps.steps .step-primary:after,
.custom-steps.steps .step-primary > .step-icon {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(39 196 50 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  --step-bg: #27c432 !important;
  --step-fg: #ffffff !important;
}
